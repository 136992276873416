import { apiRoutes } from '@services/constants';
import type { InvoiceObject, InvoiceResponse } from '@services/invoices/types';
import { get, getFileBlob, post } from '../../utils/fetch';

export const getAllPendingInvoices = async (): Promise<InvoiceObject[]> => {
  const response = await get<InvoiceResponse>(apiRoutes.invoices.getAllPending);
  return response.invoices;
};

export const getInvoiceArrAging = async (search: string): Promise<Blob> => {
  const response = await getFileBlob(
    apiRoutes.invoices.getInvoiceArrAging,
    {
      search,
    },
    {
      headers: {
        'Content-Type': 'text/csv',
      },
    },
  );
  return response;
};

export const payAllInvoices = async (): Promise<boolean> => {
  const response = await post<{}, { scheduled: boolean }>(apiRoutes.invoices.submitAutoPay, {});
  return response.scheduled;
};
