"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestConfigEnum = void 0;
var QuestConfigEnum;
(function (QuestConfigEnum) {
    QuestConfigEnum["DECEMBER_HOURS_CHAMP"] = "DECEMBER_HOURS_CHAMP";
    QuestConfigEnum["WORKED_HOURS"] = "WORKED_HOURS";
    QuestConfigEnum["WORKED_HOURS_TIER_2"] = "WORKED_HOURS_TIER_2";
    QuestConfigEnum["WORKED_HOURS_TIER_3"] = "WORKED_HOURS_TIER_3";
    QuestConfigEnum["VISITS_ACCEPTED"] = "VISITS_ACCEPTED";
    QuestConfigEnum["DESIRABLE_CHECK_IN_METHOD"] = "DESIRABLE_CHECK_IN_METHOD";
    QuestConfigEnum["DESIRABLE_CHECK_IN_METHOD_CHALLENGE"] = "DESIRABLE_CHECK_IN_METHOD_CHALLENGE";
    QuestConfigEnum["DESIRABLE_CHECK_IN_METHOD_MONTH"] = "DESIRABLE_CHECK_IN_METHOD_MONTH";
    QuestConfigEnum["DESIRABLE_CHECK_IN_METHOD_HOLIDAY"] = "DESIRABLE_CHECK_IN_METHOD_HOLIDAY";
    QuestConfigEnum["ONBOARDING"] = "ONBOARDING";
    QuestConfigEnum["NO_CANCELLED_SHIFTS"] = "NO_CANCELLED_SHIFTS";
    QuestConfigEnum["NO_CANCELLED_SHIFTS_WEEKLY"] = "NO_CANCELLED_SHIFTS_WEEKLY";
    QuestConfigEnum["NO_CANCELLED_SHIFTS_MONTHLY"] = "NO_CANCELLED_SHIFTS_MONTHLY";
    QuestConfigEnum["NO_CANCELLED_SHIFTS_HOLIDAY"] = "NO_CANCELLED_SHIFTS_HOLIDAY";
    QuestConfigEnum["NO_PLANNED_SHIFTS"] = "NO_PLANNED_SHIFTS";
    QuestConfigEnum["ON_TIME_CHECK_IN"] = "ON_TIME_CHECK_IN";
    QuestConfigEnum["ON_TIME_CHECK_IN_HOLIDAY"] = "ON_TIME_CHECK_IN_HOLIDAY";
    QuestConfigEnum["SURVEY"] = "SURVEY";
    QuestConfigEnum["TIMELY_COMPLETES"] = "TIMELY_COMPLETES";
    QuestConfigEnum["POLICY_QUIZ"] = "POLICY_QUIZ";
    QuestConfigEnum["COMPLETED_VISITS_PLAN"] = "COMPLETED_VISITS_PLAN";
    QuestConfigEnum["PATIENT_CALL_REMINDERS_MISSION"] = "PATIENT_CALL_REMINDERS_MISSION";
    QuestConfigEnum["ADDITIONAL_HOURS"] = "ADDITIONAL_HOURS";
    QuestConfigEnum["VISITS_COMPLETED"] = "VISITS_COMPLETED";
    QuestConfigEnum["WEEKEND_WARRIOR1"] = "WEEKEND_WARRIOR1";
    QuestConfigEnum["WEEKEND_WARRIOR2"] = "WEEKEND_WARRIOR2";
    QuestConfigEnum["SOC_VISITS"] = "SOC_VISITS";
    QuestConfigEnum["MONTHLY_PRODUCTIVITY_POINTS_MISSION"] = "MONTHLY_PRODUCTIVITY_POINTS_MISSION";
    QuestConfigEnum["WEEKLY_PRODUCTIVITY_POINTS_MISSION"] = "WEEKLY_PRODUCTIVITY_POINTS_MISSION";
    QuestConfigEnum["ONBOARDING_PRODUCTIVITY_POINTS_MISSION"] = "ONBOARDING_PRODUCTIVITY_POINTS_MISSION";
    QuestConfigEnum["THE_BIG_100"] = "THE_BIG_100";
    QuestConfigEnum["THE_AWESOME_75"] = "THE_AWESOME_75";
    QuestConfigEnum["ON_TIME_DOCUMENTATION_MISSION"] = "ON_TIME_DOCUMENTATION_MISSION";
    QuestConfigEnum["WEEKLY_HOURS_WORKED"] = "WEEKLY_HOURS_WORKED";
    QuestConfigEnum["PERFECT_ATTENDENCE"] = "PERFECT_ATTENDENCE";
    QuestConfigEnum["AN_ANGEL_GETS_ITS_WINGS"] = "AN_ANGEL_GETS_ITS_WINGS";
    QuestConfigEnum["ANGEL_READY"] = "ANGEL_READY";
    QuestConfigEnum["PERFECT_ANGEL"] = "PERFECT_ANGEL";
})(QuestConfigEnum || (exports.QuestConfigEnum = QuestConfigEnum = {}));
