import { Button, Typography } from '@mui/material';
import { Modal, ModalBody, ModalContent, ModalFooter } from '@nextui-org/modal';
import { getAllPendingInvoices, payAllInvoices } from '@services/invoices';
import React from 'react';
import { useTheme } from 'styled-components';
import type { InvoiceObject } from '../../../../services/invoices/types';

type AgencyAutoPayModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const AgencyAutoPayModal = ({ open, setOpen }: AgencyAutoPayModalProps) => {
  const theme = useTheme();
  const [pendingInvoices, setPendingInvoices] = React.useState<InvoiceObject[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [result, setResult] = React.useState<'success' | 'error' | null>(null);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    getAllPendingInvoices()
      .then((invoices) => setPendingInvoices(invoices))
      .finally(() => setIsLoading(false));
  }, []);

  const onSubmit = React.useCallback(() => {
    setSubmitting(true);
    payAllInvoices()
      .then(() => setResult('success'))
      .catch(() => setResult('error'))
      .finally(() => setSubmitting(false));
  }, [setResult, setSubmitting]);

  return (
    <Modal isOpen={open}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalBody>
          <div className="flex flex-col w-full h-full p-6 rounded">
            <div className="flex items-center justify-center w-full py-8">
              <Typography>
                {result === 'success'
                  ? 'Successfully submitted invoices for auto-pay!'
                  : result === 'error'
                    ? 'Failed to submit invoices for auto-pay, ping engineering'
                    : isLoading
                      ? 'Loading...'
                      : `${pendingInvoices.length} Invoices ready for Auto Pay`}
              </Typography>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: `1px solid ${theme.colors.gray[100]}` }}>
          <Button size="small" color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            size="medium"
            color="primary"
            onClick={onSubmit}
            disabled={pendingInvoices.length === 0 || isLoading || submitting}
          >
            Confirm Auto-Pay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
