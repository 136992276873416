import PageTitle from '@components/PageTitle';
import { Typography } from '@mui/material';
import { Button, Card, CardBody, CardHeader, Chip, Link } from '@nextui-org/react';
import { handleViewAdminClick, handleViewCaregiverClick } from '@pages/Agencies/index';
import { getAgencyDetails } from '@services/agencies';
import type { AgencyDetailsObject } from '@services/agencies/types';
import { getJobPosts } from '@services/jobPosts';
import type { JobPostObject } from '@services/jobPosts/types';
import { formatDate } from '@utils/date';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AgencyDetails = () => {
  const [agency, setAgency] = useState<AgencyDetailsObject>();
  const [jobPosts, setJobPosts] = useState<JobPostObject[]>([]);
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  const getAgency = async () => {
    if (!id) return;

    const agencyResponse = await getAgencyDetails(id);

    if (agencyResponse) setAgency(agencyResponse);
  };

  const getJobPostsList = async () => {
    if (!id) return;

    const jobPostsResponse = await getJobPosts('0', '3', id);

    if (jobPostsResponse) setJobPosts(jobPostsResponse);
  };

  useEffect(() => {
    getAgency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getJobPostsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-between">
        <PageTitle>Agency - {agency?.name}</PageTitle>
        <div className="flex flex-row gap-2 grow justify-end items-center">
          <Button
            onClick={() => navigate(`/agencies/${id}/sms-manager`)}
            style={{
              background: '#6558F5',
              color: 'white',
            }}
          >
            Manage SMS
          </Button>
          <Button
            onClick={() => navigate(`/agencies/${id}/rewards`)}
            style={{
              background: '#6558F5',
              color: 'white',
            }}
          >
            Manage Rewards
          </Button>
          <Button
            onClick={() => navigate(`/agencies/${id}/missions`)}
            style={{
              background: '#6558F5',
              color: 'white',
            }}
          >
            Manage Missions
          </Button>
          <Button
            onClick={() => navigate(`/agencies/${id}/profile-filter`)}
            style={{
              background: '#6558F5',
              color: 'white',
            }}
          >
            Profile Filter
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-8">
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Launch date</span>
          </CardHeader>
          <CardBody>{formatDate(agency?.launchDate || '')}</CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Timezone</span>
          </CardHeader>
          <CardBody>
            <span className="text-base">{agency?.timezone}</span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Point name</span>
          </CardHeader>
          <CardBody>
            <span className="text-base">{agency?.pointName}</span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Point name</span>
          </CardHeader>
          <CardBody>
            <span className="text-base">{agency?.pointName}</span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Points</span>
          </CardHeader>
          <CardBody className="gap-y-2">
            <span className="flex justify-between text-base">
              Treasure box price
              <Chip color="secondary">{agency?.treasureBoxPrice || 0}</Chip>
            </span>
            <span className="flex justify-between text-base">
              Base giftcard price <Chip color="secondary">{agency?.baseGiftcardPrice || 0}</Chip>
            </span>
            <span className="flex justify-between text-base">
              Click in streak points{' '}
              <Chip color="secondary">{agency?.clickInStreakPoints || 0}</Chip>
            </span>
            <span className="flex justify-between text-base">
              Weekly hours streak points{' '}
              <Chip color="secondary">{agency?.weeklyHoursStreakPoints || 0}</Chip>
            </span>
            <span className="flex justify-between text-base">
              Acceptable delay in minutes{' '}
              <Chip color="secondary">{agency?.acceptableDelayInMinutes || 0}</Chip>
            </span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Links</span>
          </CardHeader>
          <CardBody className="gap-2">
            <span className="flex text-base">
              <Typography
                className="underline cursor-pointer"
                onClick={() => agency && handleViewCaregiverClick(agency.id)}
              >
                APP
              </Typography>
            </span>
            <span className="text-base">
              <Typography
                className="underline cursor-pointer"
                onClick={() => agency && handleViewAdminClick(agency.id)}
              >
                DASHBOARD
              </Typography>
            </span>
            <span className="text-base">
              <Link showAnchorIcon className="cursor-pointer" href={agency?.engagementSurveyUrl}>
                ENGAGEMENT SURVEY
              </Link>
            </span>
            {agency?.proposal?.proposalLink && (
              <span className="text-base">
                <Typography
                  className="underline cursor-pointer"
                  onClick={() => window.open(agency.proposal?.proposalLink ?? '', '_blank')}
                >
                  PROPOSAL LINK
                </Typography>
              </span>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Data provider</span>
          </CardHeader>
          <CardBody>
            <span className="text-base">{agency?.dataProvider}</span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <div className="flex justify-between w-full pr-2">
              <span className="text-lg font-bold">Job posts</span>
              <span
                className="text-base cursor-pointer underline"
                onClick={() => navigate(`/${id}/job-post`)}
              >
                View all
              </span>
            </div>
          </CardHeader>
          <CardBody>
            {jobPosts.map((jobPost) => (
              <span className="text-base">{jobPost.title}</span>
            ))}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AgencyDetails;
