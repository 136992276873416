import PageTitle from '@components/PageTitle';
import { Delete } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Button, Input, Select, SelectItem, Spinner, Switch, Textarea } from '@nextui-org/react';
import { MultiplierConfigDataCard } from '@pages/Templates/Details/components/MultiplierConfigDataCard';
import { RewardConfigDataCard } from '@pages/Templates/Details/components/RewardConfigDataCard';
import { UniquenessConfigDataCard } from '@pages/Templates/Details/components/UniquenessConfigDataCard';
import { RewardConfigTriggerType, RewardVisibility } from '@perry/db';
import type { BaseCreateRewardConfigParams } from '@perry/shared-types';
import { deleteRewardConfig, getRewardConfig, updateRewardConfig } from '@services/templates';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const RewardConfigDetails = () => {
  const { rewardId, id: agencyId } = useParams<{ rewardId: string; id: string }>();
  const isNewTemplate = !rewardId;
  const [template, setTemplate] = React.useState<BaseCreateRewardConfigParams | null>(null);

  const navigate = useNavigate();

  const handleUpdate = (update: Partial<BaseCreateRewardConfigParams>) => {
    setTemplate((params) => {
      if (!params) return params;
      return { ...params, ...update };
    });
  };

  const handleSubmit = async () => {
    try {
      if (!template || !rewardId) return null;
      const response = await updateRewardConfig(parseInt(rewardId), template);
      if (!response.rewardId) {
        toast.error('Failed to update reward template.');
        return;
      }
      toast.success('Reward updated successfully');
    } catch (error) {
      console.error(error);
      toast.error(isNewTemplate ? 'Error creating reward' : 'Error updating reward');
    }
  };

  const handleDelete = async () => {
    window.confirm('Are you sure you want to delete this reward? This is irreversible.');
    if (!rewardId) return;
    const response = await deleteRewardConfig(parseInt(rewardId), false);
    if (!response.rewardId) {
      toast.error('Failed to delete reward template.');
      return;
    }
    navigate(`/agencies/${agencyId}/rewards`);
    toast.success('Reward deleted successfully');
  };

  const handleCancel = () => {
    navigate(`/agencies/${agencyId}/rewards`);
  };

  const getSelectedTemplate = React.useCallback(async () => {
    if (!rewardId) return;

    const response = await getRewardConfig(rewardId);
    if (response) setTemplate(response.config);
  }, [rewardId]);

  React.useEffect(() => {
    if (!isNewTemplate) {
      getSelectedTemplate();
    }
  }, [getSelectedTemplate, isNewTemplate]);

  if (!template)
    return (
      <div className="flex justify-center items-center h-full w-full">
        <Spinner />
      </div>
    );

  return (
    <>
      <div className="flex flex-col h-full w-full">
        <PageTitle>
          <div className="flex justify-between flex-row item-center">
            {isNewTemplate ? 'Create Reward Template' : 'Reward Template Edit Details'}
            <div className="flex flex-row items-center gap-2">
              <Typography variant="body1">Active?</Typography>
              <Switch
                checked={template.activated}
                onChange={(e) => handleUpdate({ activated: e.target.checked })}
              />
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            </div>
          </div>
        </PageTitle>
        <div className="grid grid-cols-12 gap-4 mt-6 grow">
          <div className="col-span-6 flex flex-col gap-4">
            <Input
              label="Title"
              type="text"
              labelPlacement="outside"
              name="title"
              value={template.title}
              onChange={(e) => handleUpdate({ title: e.target.value, name: e.target.value })}
            />
            <Textarea
              label="Description"
              type="text"
              labelPlacement="outside"
              name="description"
              value={template.description}
              minRows={2}
              onChange={(e) => handleUpdate({ description: e.target.value })}
            />
            <Select
              label="What entity triggers this reward?"
              selectedKeys={[template.triggerType]}
              labelPlacement="outside"
              name="triggerType"
              value={template.triggerType}
              onChange={(e) =>
                handleUpdate({ triggerType: e.target.value as RewardConfigTriggerType })
              }
              required
            >
              <SelectItem
                value={RewardConfigTriggerType.PROFILE}
                key={RewardConfigTriggerType.PROFILE}
              >
                Profile
              </SelectItem>
              <SelectItem value={RewardConfigTriggerType.VISIT} key={RewardConfigTriggerType.VISIT}>
                Visit
              </SelectItem>
            </Select>
            <Input
              label="Point Value"
              type="number"
              labelPlacement="outside"
              name="pointAmount"
              value={template.pointAmount?.toString()}
              onChange={(e) => handleUpdate({ pointAmount: parseInt(e.target.value) })}
            />
            <Select
              label="Post Visibility"
              labelPlacement="outside"
              name="visibility"
              selectedKeys={[template.visibility]}
              value={template.visibility}
              onChange={(e) => handleUpdate({ visibility: e.target.value as RewardVisibility })}
              required
            >
              <SelectItem value={RewardVisibility.PRIVATE} key={RewardVisibility.PRIVATE}>
                Private
              </SelectItem>
              <SelectItem value={RewardVisibility.PUBLIC} key={RewardVisibility.PUBLIC}>
                Public
              </SelectItem>
              <SelectItem
                value={RewardVisibility.LOCATION_SPECIFIC}
                key={RewardVisibility.LOCATION_SPECIFIC}
              >
                Specific to Location
              </SelectItem>
            </Select>
          </div>
          <div className="col-span-6 flex flex-col gap-4">
            <RewardConfigDataCard
              configData={template.configData}
              setConfigData={(data) => handleUpdate({ configData: data })}
            />
            <UniquenessConfigDataCard
              uniquenessConfig={template.uniquenessConfig}
              setUniquenessConfig={(data) => handleUpdate({ uniquenessConfig: data })}
            />
            <MultiplierConfigDataCard
              multiplierConfig={template.multiplierConfig ?? null}
              setMultiplierConfig={(data) => handleUpdate({ multiplierConfig: data })}
            />
          </div>
        </div>
        <div className="flex justify-end gap-6 mt-6">
          <Button type="button" variant="bordered" className="w-56" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="solid"
            className="w-56 bg-theme-primary text-white"
          >
            {isNewTemplate ? 'Create' : 'Save'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RewardConfigDetails;
